<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col class="section-title">
          {{ t('Title_.subTitle.finalStep') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="section-title darkBlueGrey--text font-weight-light"
            v-for="(text, textIndex) in tm('Msg_.text.uploadDocument')"
            :key="textIndex"
          >
            <p v-for="(line, lineIndex) in text" :key="lineIndex">
              {{ line }}
            </p>
            <br />
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col cols="12">
          <v-btn
            class="cancel-outlined-btn"
            @click="previousStep()"
            :disabled="mainStore.isPostFormInProgress"
          >
            {{ t('Action_.buttons.back') }}
          </v-btn>

          <v-btn
            class="main-action-btn ml-3"
            @click="uploadAssignmentValues()"
            :disabled="mainStore.isPostFormInProgress"
            :loading="mainStore.isPostFormInProgress"
          >
            {{ t('Action_.buttons.submit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import languages from '../locales/languages';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router/composables';

const router = useRouter();
const mainStore = useMainStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const scrollUp = () => {
  window.scrollTo(0, 0);
};

const previousStep = () => {
  fleetVehiclesStore.stepperCurrentStep = 2;
  scrollUp();
};

const getAssignmentEmployeeInformationFields = (
  assignmentEmployeeInformation
) => {
  return {
    GrantedBy: assignmentEmployeeInformation.handler || null,
    EmployeeId: assignmentEmployeeInformation.assigned || null,
    HubId: assignmentEmployeeInformation.hubId || null,
    GasCard: assignmentEmployeeInformation.gasCard || null,
    TaxSelection: parseFloat(assignmentEmployeeInformation.taxSelection) || 0,
    TaxSelectionType: assignmentEmployeeInformation.taxSelectionType || null,
    DriversLicenseNumber:
      assignmentEmployeeInformation.driverLicenseNumber || null,
    DriversLicenseClass:
      assignmentEmployeeInformation.driverLicenseClass || null,
    DriversLicenseIssuedAt: assignmentEmployeeInformation.dateOfIssue
      ? new Date(assignmentEmployeeInformation.dateOfIssue).toISOString()
      : null,
    DriversLicenseExpiresAt: assignmentEmployeeInformation.dateOfExpiry
      ? new Date(assignmentEmployeeInformation.dateOfExpiry).toISOString()
      : null,
    DriversLicenseIssuingAuthority:
      assignmentEmployeeInformation.issuingAuthority || null,
  };
};

const getAssignmentCarCondition = (assignmentCarCondition) => {
  return {
    VehicleId: assignmentCarCondition.id || null,
    KmStandOnGrant: assignmentCarCondition.kmStand
      ? parseFloat(assignmentCarCondition.kmStand)
      : null,
    DamageAtDriverSideOnGrant: assignmentCarCondition.driverSide || null,
    DamageAtPassengerSideOnGrant: assignmentCarCondition.passengerSide || null,
    DamageAboveOnGrant: assignmentCarCondition.above || null,
    DamageFrontOnGrant: assignmentCarCondition.front || null,
    DamageBackOnGrant: assignmentCarCondition.back || null,
    DamageInteriorOnGrant: assignmentCarCondition.interior || null,
  };
};

const uploadAssignmentValues = async () => {
  mainStore.isPostFormInProgress = true;

  const payload = {
    ...getAssignmentEmployeeInformationFields(
      fleetVehiclesStore.assignmentEmployeeInformation
    ),
    ...getAssignmentCarCondition(fleetVehiclesStore.assignmentCarCondition),
  };

  // const toastParams = {
  //   type: 'success',
  // };
  // this.$root.message.setToastParams(toastParams);

  try {
    await fleetVehiclesStore.postAssignmentInformation(payload);
    router.push({
      name: 'FleetMenu',
      params: { vehicleId: payload.VehicleId, ignoreChanges: true },
    });
    // this.$root.message.showToastNotification();
  } catch (error) {
    mainStore.isPostFormInProgress = false;
    console.log(error);
  }
};

// --- Lifecycle hooks ---
onBeforeUnmount(() => {
  mainStore.isPostFormInProgress = false;
});
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
