var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-container',[_c('v-form',{model:{value:(_setup.valid),callback:function ($$v) {_setup.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.assignedDriver'))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('p',{staticClass:"custom-label mb-1"},[_vm._v(_vm._s(_setup.t('Msg_.employee') + '*'))]),_c('v-autocomplete',{attrs:{"items":_setup.items,"search-input":_setup.search,"dense":"","outlined":"","hide-no-data":"","append-icon":"","prepend-inner-icon":"mdi-magnify","color":"blueRegular","item-text":"completeName","item-value":"id","rules":[_setup.rules.fieldRequired(_setup.t('Msg_.rules.employeeRequired'))],"loading":_setup.loadingStore.isPartialLoading},on:{"update:searchInput":function($event){_setup.search=$event},"update:search-input":function($event){_setup.search=$event},"change":function($event){return _setup.updateAssignedEmployee($event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"employee-name",class:{
                      'no-hub': data.item.office === 'No Hub',
                    }},[_vm._v(_vm._s(data.item.completeName)+" ")]),_c('v-list-item-subtitle',{staticClass:"employee-office",class:{
                      'no-hub': data.item.office === 'No Hub',
                    }},[_vm._v(_vm._s(data.item.office)+" ")])],1)]}}]),model:{value:(_setup.select),callback:function ($$v) {_setup.select=$$v},expression:"select"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.jobPosition'),"value":_setup.jobPosition,"disabled":true}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.office'),"disabled":true},model:{value:(_setup.fleetVehiclesStore.assignmentEmployeeInformation.office),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation, "office", $$v)},expression:"fleetVehiclesStore.assignmentEmployeeInformation.office"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.gasCard'),"disabled":true},model:{value:(_setup.fleetVehiclesStore.assignmentEmployeeInformation.gasCard),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation, "gasCard", $$v)},expression:"fleetVehiclesStore.assignmentEmployeeInformation.gasCard"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"label":_setup.t('Msg_.taxSelection') + '*',"items":_setup.populateTaxes(),"rules":[
              _setup.rules.fieldRequired(_setup.t('Msg_.rules.taxSelectionRequired')),
            ]},on:{"change":function($event){return _setup.updateAssignmentEmployeeTaxFields($event)}},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation
                .taxSelectionType
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation
                , "taxSelectionType", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation\n                .taxSelectionType\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.phoneNumber'),"disabled":true},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation.phoneNumber
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation, "phoneNumber", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation.phoneNumber\n            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.driverLicenseNumber') + '*',"rules":[
              _setup.rules.fieldRequired(_setup.t('Msg_.rules.driverLicenseRequired')),
            ]},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation
                .driverLicenseNumber
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation
                , "driverLicenseNumber", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation\n                .driverLicenseNumber\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.t('Msg_.dateOfIssue')},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation.dateOfIssue
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation, "dateOfIssue", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation.dateOfIssue\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.issuingAuthority') + '*',"rules":[
              _setup.rules.fieldRequired(_setup.t('Msg_.rules.issuingAuthorityRequired')),
            ]},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation
                .issuingAuthority
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation
                , "issuingAuthority", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation\n                .issuingAuthority\n            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"label":_setup.t('Msg_.driverLicenseClass') + '*',"items":_setup.driverLicense,"rules":[
              _setup.rules.fieldRequired(_setup.t('Msg_.rules.licenseClassRequired')),
            ]},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation
                .driverLicenseClass
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation
                , "driverLicenseClass", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation\n                .driverLicenseClass\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.t('Msg_.dateOfExpiry'),"min":_setup.nowDate},model:{value:(
              _setup.fleetVehiclesStore.assignmentEmployeeInformation.dateOfExpiry
            ),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.assignmentEmployeeInformation, "dateOfExpiry", $$v)},expression:"\n              fleetVehiclesStore.assignmentEmployeeInformation.dateOfExpiry\n            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.recorder'),"disabled":true},model:{value:(_setup.userStore.userInfo.preferred_username),callback:function ($$v) {_vm.$set(_setup.userStore.userInfo, "preferred_username", $$v)},expression:"userStore.userInfo.preferred_username"}})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"cancel-variant-btn",on:{"click":function($event){return _setup.routeToVehiclesList()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-3",attrs:{"disabled":!_setup.valid || _setup.loadingStore.isPartialLoading},on:{"click":function($event){return _setup.nextStep()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.next'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }