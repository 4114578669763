<template>
  <v-container>
    <p class="card-title py-1">
      {{ t('Title_.subTitle.assignVehicle') }} -
      <span class="blueLight--text">
        {{ vehiclePlate }}
      </span>
    </p>

    <v-card class="shadow-regular">
      <v-stepper
        :value="fleetVehiclesStore.stepperCurrentStep"
        alt-labels
        class="elevation-0"
      >
        <v-row justify="center">
          <v-col cols="6">
            <v-stepper-header class="elevation-0">
              <v-stepper-step
                color="caribbeanRegular"
                :complete="fleetVehiclesStore.stepperCurrentStep > 1"
                step="1"
              >
                <small
                  :class="[
                    fleetVehiclesStore.stepperCurrentStep === 1
                      ? 'stepper-center-text font-weight-bold'
                      : 'stepper-center-text stepper-text',
                  ]"
                >
                  {{ t('Action_.stepper.employeeInformation') }}
                </small>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                color="caribbeanRegular"
                :complete="fleetVehiclesStore.stepperCurrentStep > 2"
                step="2"
              >
                <small
                  :class="[
                    fleetVehiclesStore.stepperCurrentStep === 2
                      ? 'stepper-center-text font-weight-bold'
                      : 'stepper-center-text stepper-text',
                  ]"
                >
                  {{ t('Action_.stepper.carCondition') }}
                </small>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="caribbeanRegular" step="3">
                <small
                  :class="[
                    fleetVehiclesStore.stepperCurrentStep >= 3
                      ? 'stepper-center-text font-weight-bold'
                      : 'stepper-center-text stepper-text',
                  ]"
                >
                  {{ t('Action_.stepper.uploadDocuments') }}
                </small>
              </v-stepper-step>
            </v-stepper-header>
          </v-col>
        </v-row>
        <v-stepper-items>
          <v-stepper-content step="1">
            <FleetAssignVehicleEmployeeInformation />
          </v-stepper-content>

          <v-stepper-content step="2">
            <FleetAssignVehicleCarCondition />
          </v-stepper-content>

          <v-stepper-content step="3">
            <FleetAssignVehicleUploadDocument />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script setup>
import FleetAssignVehicleEmployeeInformation from './assignVehicle/fleet-assign-vehicle-employee-information.vue';
import FleetAssignVehicleCarCondition from './assignVehicle/fleet-assign-vehicle-car-condition.vue';
import FleetAssignVehicleUploadDocument from './assignVehicle/fleet-assign-vehicle-upload-document.vue';
import languages from './locales/languages';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { ref, computed, onBeforeUnmount } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';

const route = useRoute();
const popupDialogStore = usePopupDialogStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variable ---
const ignoreFormChanges = ref(false);
const nextRoute = ref(null);
const unmodifiedForm = ref(null);

// --- Methods ---
const getVehicleDetails = async () => {
  try {
    await fleetVehiclesStore.getVehicleDetails(route.params.vehicleId);
    unmodifiedForm.value = {
      ...{},
      ...fleetVehiclesStore.assignmentEmployeeInformation,
    };
  } catch (error) {
    console.log(error);
  }
};

const displayConfirmationDialog = (next) => {
  const actionBtn = () => {
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.wantToCancelAssignment.title'),
    message: t('Action_.dialog.wantToCancelAssignment.body'),
    reinforcement: t('Action_.dialog.wantToCancelAssignment.reinforcement'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const isFormAltered = () => {
  if (unmodifiedForm.value) {
    return !helpers.compareTwoObjects(
      fleetVehiclesStore.assignmentEmployeeInformation,
      unmodifiedForm.value
    );
  }
  return false;
};

// --- Computed ---
const vehiclePlate = computed(() => {
  let plate = '';
  plate = fleetVehiclesStore.vehicleDetails?.plate;
  return plate;
});

// --- Lifecycle hooks ---
getVehicleDetails();

onBeforeUnmount(() => {
  fleetVehiclesStore.resetAssignmentEmployeeInformation();
  fleetVehiclesStore.resetAssignmentCarCondition();
  fleetVehiclesStore.stepperCurrentStep = 1;
  nextRoute.value = null;
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (
    !isFormAltered() ||
    ignoreFormChanges.value ||
    to.params.ignoreChanges ||
    to.name === 'Error'
  ) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    displayConfirmationDialog(next);
  }
});
</script>

<style scoped lang="scss">
.stepper-center-text {
  text-align: -webkit-center;
  font-size: 0.65rem;
}
</style>
