var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('p',{staticClass:"card-title py-1"},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.assignVehicle'))+" - "),_c('span',{staticClass:"blueLight--text"},[_vm._v(" "+_vm._s(_setup.vehiclePlate)+" ")])]),_c('v-card',{staticClass:"shadow-regular"},[_c('v-stepper',{staticClass:"elevation-0",attrs:{"value":_setup.fleetVehiclesStore.stepperCurrentStep,"alt-labels":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-stepper-header',{staticClass:"elevation-0"},[_c('v-stepper-step',{attrs:{"color":"caribbeanRegular","complete":_setup.fleetVehiclesStore.stepperCurrentStep > 1,"step":"1"}},[_c('small',{class:[
                  _setup.fleetVehiclesStore.stepperCurrentStep === 1
                    ? 'stepper-center-text font-weight-bold'
                    : 'stepper-center-text stepper-text',
                ]},[_vm._v(" "+_vm._s(_setup.t('Action_.stepper.employeeInformation'))+" ")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"color":"caribbeanRegular","complete":_setup.fleetVehiclesStore.stepperCurrentStep > 2,"step":"2"}},[_c('small',{class:[
                  _setup.fleetVehiclesStore.stepperCurrentStep === 2
                    ? 'stepper-center-text font-weight-bold'
                    : 'stepper-center-text stepper-text',
                ]},[_vm._v(" "+_vm._s(_setup.t('Action_.stepper.carCondition'))+" ")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"color":"caribbeanRegular","step":"3"}},[_c('small',{class:[
                  _setup.fleetVehiclesStore.stepperCurrentStep >= 3
                    ? 'stepper-center-text font-weight-bold'
                    : 'stepper-center-text stepper-text',
                ]},[_vm._v(" "+_vm._s(_setup.t('Action_.stepper.uploadDocuments'))+" ")])])],1)],1)],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c(_setup.FleetAssignVehicleEmployeeInformation)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c(_setup.FleetAssignVehicleCarCondition)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c(_setup.FleetAssignVehicleUploadDocument)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }