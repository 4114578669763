import { render, staticRenderFns } from "./fleet-assign-vehicle-employee-information.vue?vue&type=template&id=53706234&scoped=true&"
import script from "./fleet-assign-vehicle-employee-information.vue?vue&type=script&setup=true&lang=js&"
export * from "./fleet-assign-vehicle-employee-information.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./fleet-assign-vehicle-employee-information.vue?vue&type=style&index=0&id=53706234&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53706234",
  null
  
)

export default component.exports