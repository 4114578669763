<template>
  <v-card>
    <v-container>
      <v-form v-model="valid">
        <v-row>
          <v-col class="section-title">
            {{ t('Title_.subTitle.assignedDriver') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <div>
              <p class="custom-label mb-1">{{ t('Msg_.employee') + '*' }}</p>
              <v-autocomplete
                v-model="select"
                :items="items"
                :search-input.sync="search"
                dense
                outlined
                hide-no-data
                append-icon=""
                prepend-inner-icon="mdi-magnify"
                color="blueRegular"
                item-text="completeName"
                item-value="id"
                :rules="[rules.fieldRequired(t('Msg_.rules.employeeRequired'))]"
                @change="updateAssignedEmployee($event)"
                :loading="loadingStore.isPartialLoading"
              >
                <template #item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      class="employee-name"
                      :class="{
                        'no-hub': data.item.office === 'No Hub',
                      }"
                      >{{ data.item.completeName }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="employee-office"
                      :class="{
                        'no-hub': data.item.office === 'No Hub',
                      }"
                      >{{ data.item.office }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div>
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              :label="t('Msg_.jobPosition')"
              :value="jobPosition"
              :disabled="true"
            />
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              :label="t('Msg_.office')"
              v-model="fleetVehiclesStore.assignmentEmployeeInformation.office"
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.gasCard')"
              v-model="fleetVehiclesStore.assignmentEmployeeInformation.gasCard"
              :disabled="true"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              :label="t('Msg_.taxSelection') + '*'"
              :items="populateTaxes()"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation
                  .taxSelectionType
              "
              :rules="[
                rules.fieldRequired(t('Msg_.rules.taxSelectionRequired')),
              ]"
              @change="updateAssignmentEmployeeTaxFields($event)"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.phoneNumber')"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation.phoneNumber
              "
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.driverLicenseNumber') + '*'"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation
                  .driverLicenseNumber
              "
              :rules="[
                rules.fieldRequired(t('Msg_.rules.driverLicenseRequired')),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DatePicker
              :label="t('Msg_.dateOfIssue')"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation.dateOfIssue
              "
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.issuingAuthority') + '*'"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation
                  .issuingAuthority
              "
              :rules="[
                rules.fieldRequired(t('Msg_.rules.issuingAuthorityRequired')),
              ]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              :label="t('Msg_.driverLicenseClass') + '*'"
              :items="driverLicense"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation
                  .driverLicenseClass
              "
              :rules="[
                rules.fieldRequired(t('Msg_.rules.licenseClassRequired')),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DatePicker
              :label="t('Msg_.dateOfExpiry')"
              :min="nowDate"
              v-model="
                fleetVehiclesStore.assignmentEmployeeInformation.dateOfExpiry
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.recorder')"
              v-model="userStore.userInfo.preferred_username"
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col cols="12">
            <v-btn class="cancel-variant-btn" @click="routeToVehiclesList()">
              {{ t('Action_.buttons.cancel') }}
            </v-btn>
            <v-btn
              class="main-action-btn ml-3"
              :disabled="!valid || loadingStore.isPartialLoading"
              @click="nextStep()"
            >
              {{ t('Action_.buttons.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script setup>
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import languages from '../locales/languages';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const router = useRouter();
const { trackPage } = amplitudeComposable();
const userStore = useUserStore();
const loadingStore = useLoadingStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const driverLicense = ref(['B', 'B1', 'BE', 'C', 'C1', 'CE', 'C1E']);
const items = ref([]);
const nowDate = ref(new Date().toISOString().slice(0, 10));
const search = ref(null);
const select = ref(null);
const valid = ref(false);

// --- Methods ---
const scrollUp = () => {
  window.scrollTo(0, 0);
};

const updateEmployeeAutomaticFilledValues = (userDetails) => {
  const valuesToUpdate = {
    qualification: userDetails.qualification,
    office: userDetails.hubName,
    phoneNumber: userDetails.phoneNumber,
    handler: userStore.userInfo.sub,
    hubId: fleetVehiclesStore.vehicleDetails.hubId,
    gasCard: fleetVehiclesStore.vehicleDetails?.fuelCard,
  };

  const information = {
    ...{},
    ...fleetVehiclesStore.assignmentEmployeeInformation,
  };

  const employeeInformation = helpers.fillObjectFromAnotherObject(
    information,
    valuesToUpdate
  );

  fleetVehiclesStore.assignmentEmployeeInformation = employeeInformation;
};

const querySelections = async (data) => {
  const user = await fleetVehiclesStore.getEmployeesByQuery(data);
  let filteredUsers = user.filter((e) => {
    return (
      (e.completeName || '').toLowerCase().indexOf((data || '').toLowerCase()) >
      -1
    );
  });

  items.value = filteredUsers.map((user) => {
    if (!user.office) {
      user.disabled = true;
      user.office = 'No Hub';
    }
    return user;
  });
};

const updateAssignedEmployee = async (userId) => {
  let userDetails;
  fleetVehiclesStore.assignmentEmployeeInformation.assigned = userId;
  if (userId) {
    userDetails = await fleetVehiclesStore.getEmployeeDetails(userId);
  } else {
    userDetails = {
      qualification: null,
      office: null,
      phoneNumber: null,
    };
  }
  updateEmployeeAutomaticFilledValues(userDetails);
};

const updateAssignmentEmployeeTaxFields = (value) => {
  let taxSelectionValue = null;
  const taxSelection =
    fleetVehiclesStore.vehicleResources.taxSelectionValues.find((taxType) => {
      return taxType.name === value;
    });
  if (taxSelection) {
    taxSelectionValue = taxSelection.value;
  }
  fleetVehiclesStore.assignmentEmployeeInformation.taxSelection =
    taxSelectionValue;
};

const nextStep = () => {
  fleetVehiclesStore.stepperCurrentStep = 2;
  trackPage(fleetVehiclesStore.vehicleDetails.hubId, {
    routeName: 'fleet.vehicleDetails.assignVehicle.carCondition',
  });
  scrollUp();
};

const populateTaxes = () => {
  const taxSelectionValues = Object.values(
    fleetVehiclesStore.vehicleResources.taxSelectionValues
  );

  const selectTaxOptions = taxSelectionValues.map((tax) => {
    const translation = helpers.convertStringToLowerCamelCase('_', tax.name);
    return {
      text: tm(`Action_.selectTaxType.${translation}`),
      value: tax.name,
    };
  });
  return selectTaxOptions;
};

const routeToVehiclesList = () => {
  router.push({ name: 'FleetVehiclesList' });
};

// --- Computed ---
const jobPosition = computed(() => {
  let actionSelectQualificationType = tm('Action_.qualificationTypes');

  return helpers.getTranslationById(
    actionSelectQualificationType,
    fleetVehiclesStore.assignmentEmployeeInformation.qualification
  );
});

// --- lifecycle ---
trackPage(fleetVehiclesStore.vehicleDetails.hubId);
watch(
  () => search.value,
  (val) => {
    val !== select.value && querySelections(val);
  }
);
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.employee-name {
  font-weight: 300 !important;
  font-size: 13px !important;
  letter-spacing: 0.4px;
  color: $mine-black;
  &.no-hub {
    color: $grey-regular !important;
  }
}

.employee-office {
  font-weight: 500 !important;
  font-size: 11px !important;
  color: $grey-light !important;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  &.no-hub {
    color: $red-danger !important;
  }
}
.v-input--is-focused ::v-deep error--text {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
