<template>
  <v-card>
    <v-container>
      <v-form v-model="valid">
        <v-row>
          <v-col class="section-title">
            {{ t('Title_.subTitle.carCondition') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <RegularTextInput
              :label="t('Msg_.kmStand') + '*'"
              v-model="fleetVehiclesStore.assignmentCarCondition.kmStand"
              :rules="[rules.fieldRequired(t('Msg_.rules.kmStandRequired'))]"
            />
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              :label="t('Msg_.vehicleType')"
              :value="vehicleType"
              :disabled="true"
            />
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              :label="t('Msg_.brand')"
              v-model="fleetVehiclesStore.vehicleDetails.brand"
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.model')"
              v-model="fleetVehiclesStore.vehicleDetails.model"
              :disabled="true"
              class="pt-0"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :label="t('Msg_.plate')"
              v-model="fleetVehiclesStore.vehicleDetails.plate"
              :disabled="true"
              class="pt-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="132"
                  width="225"
                  src="@/assets/fleet/driver_side.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  :label="t('Msg_.driverSide')"
                  v-model="fleetVehiclesStore.assignmentCarCondition.driverSide"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="140"
                  width="225"
                  src="@/assets/fleet/above.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-n2">
              <v-col>
                <TextArea
                  :label="t('Msg_.above')"
                  v-model="fleetVehiclesStore.assignmentCarCondition.above"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="132"
                  width="225"
                  src="@/assets/fleet/passenger_side.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  :label="t('Msg_.passengerSide')"
                  v-model="
                    fleetVehiclesStore.assignmentCarCondition.passengerSide
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="132"
                  width="225"
                  src="@/assets/fleet/front.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  :label="t('Msg_.front')"
                  v-model="fleetVehiclesStore.assignmentCarCondition.front"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="pt-0">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="132"
                  width="225"
                  src="@/assets/fleet/back.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  :label="t('Msg_.back')"
                  v-model="fleetVehiclesStore.assignmentCarCondition.back"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="pt-0">
            <v-row no-gutters>
              <v-col align="center">
                <v-img
                  contain
                  height="132"
                  width="225"
                  src="@/assets/fleet/interior.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  :label="t('Msg_.interior')"
                  v-model="fleetVehiclesStore.assignmentCarCondition.interior"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12">
            <v-btn class="cancel-outlined-btn" @click="previousStep()">
              {{ t('Action_.buttons.back') }}
            </v-btn>
            <v-btn
              class="main-action-btn ml-3"
              :disabled="!valid"
              @click="nextStep()"
            >
              {{ t('Action_.buttons.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import TextArea from '@/components/shared/customComponents/inputs/text-area.vue';
import languages from '../locales/languages';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { ref, computed } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();

const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const valid = ref(false);

// --- Methods ---
const scrollUp = () => {
  window.scrollTo(0, 0);
};

const previousStep = () => {
  fleetVehiclesStore.stepperCurrentStep = 1;
  scrollUp();
};

const nextStep = () => {
  fleetVehiclesStore.assignmentCarCondition.id =
    fleetVehiclesStore.vehicleDetails.id;
  fleetVehiclesStore.stepperCurrentStep = 3;
  trackPage(fleetVehiclesStore.vehicleDetails.hubId, {
    routeName: 'fleet.vehicleDetails.assignVehicle.uploadDocuments',
  });
  scrollUp();
};

// --- Computed ---
const vehicleType = computed(() => {
  let actionSelectVehicleType = tm('Action_.selectVehicleType');

  return helpers.getTranslationById(
    actionSelectVehicleType,
    fleetVehiclesStore.vehicleDetails.type
  );
});
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}
</style>
